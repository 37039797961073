import React, { useState, useEffect, useContext } from "react"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from '../components/seo'
import { graphql, Link } from "gatsby"
import {
  Container,
 
  SwiperContainer,
  Wrapper,
  Header,
  HeaderTitle,
  Like,
  Produktbeschreibung,
  Catchphrase,
  ConfigContainer,




  Right,
  
  CallToActionContainer,

  DetailsUl,
  DetailsLi,

  Price,
  
  InnerModalContainer,
  ConfigUL,
  ConfigLi,

  GeneralDetailsHeadline,
  GeneralDetailsContainer,
  GeneralDetailsLabel,
  GeneralDetailsWrapper,
  GeneralDetailsUl,

  VisitUsContainer,
  VisitUsHead,
  VisitUsBody,
  PdfLink
} from "../styles/templates/generalStyles"
import Heart from "../components/heart"
import FilledHeart from "../components/filledHeart"
import { FavContext } from '../context/FavContext'

import Modal from "../components/Modal"
// import FormModal from "../components/form-modal"
// import SingleProductForm from "../components/utilities/singleProductForm"
import SuperSwiper from "../components/Swiper"

import AeroPdf from '../downloads/aero tischsystem.pdf'
import ElisaPdf from '../downloads/elisa tischsystem.pdf'
import FebruPdf from '../downloads/febru tischsystem.pdf'
import GretaPdf from '../downloads/greta tischsystem.pdf'
import MatheoPdf from '../downloads/matheo tischsystem.pdf'
import MatzePdf from '../downloads/matze tischsystem.pdf'
import MiloPdf from '../downloads/milo tischsytem.pdf'
import RudiPdf from '../downloads/rudi tischsytem.pdf'
import TheoPdf from '../downloads/theo tischsystem.pdf'
import TravelPdf from '../downloads/travel tischsystem.pdf'


const Template = ({ data, props }) => {
  const item = data.allWpTisch.nodes[0]

  // hasVAriants?

  const { favs, addFav, removeFav, setAdded } = useContext(FavContext)

  const [isFav, setIsFav] = useState(false)
  


  const itemSlides = item.postTypeTischeFields.slides;
  const itemSlidesLf = itemSlides.map(slide => slide.localFile);
  const itemSlideCIS = itemSlidesLf.map(slide => slide.childImageSharp);
  const itemSlidesFluid = itemSlideCIS.map(slide => slide.fluid);
  const altText = itemSlides.map(slide => slide.altText);

  const moebel = item.tags.nodes.map(node => node.name)
  const variant = item.ausfuehrungen.nodes.map(node => node.name)

  const itemId = item.id
  const itemSlug = `/tische/${item.slug}`
  const itemName = moebel[0]
  const itemPrice = item.postTypeTischeFields.price
  const itemVariant = variant[0]
  const itemImg = itemSlidesFluid[0]




  useEffect(() => {
    if (favs.some(fav => fav.id === itemId)) {
      setIsFav(true)
    }
  }, [isFav, favs, itemId])

  const handleAdd = (e) => {
    e.preventDefault();
    addFav(
      itemImg,
      itemName,
      itemVariant,
      itemPrice,
      itemSlug,
      itemId)
      .then(() => {
        setIsFav(true)
        setAdded(true);
      })
  }

  const handleRemove = (e) => {
    e.preventDefault();
    removeFav(itemId)
      .then(() => {
        setIsFav(false)
      })
  }

  return (
    <Layout>
      <SEO title={variant + " " + moebel}></SEO>
      <Container>
        <Wrapper>
          <SwiperContainer>
            <SuperSwiper
              alt={altText}
              slideData={itemSlides}
            />
          </SwiperContainer>
          <Right>
            <Header>
              <div>
                <HeaderTitle>{moebel}</HeaderTitle>
              </div>

            </Header>

            <Catchphrase>"{item.postTypeTischeFields.claim}"</Catchphrase>

            <Produktbeschreibung>
              {item.postTypeTischeFields.description}
            </Produktbeschreibung>

            <ConfigContainer>
              <Modal name="Varianten">
                <InnerModalContainer>
                  <ConfigUL>
                    {item.postTypeTischeFields.variants === null ?
                      <> <div style={{width: "100%"}}> {moebel[0] === "Rudi" &&
                      <PdfLink href={RudiPdf} download>PDF</PdfLink>
                    }
                    {moebel[0] === "Travel" &&
                      <PdfLink href={TravelPdf} download>PDF</PdfLink>
                    }
                    {moebel[0] === "Febru" &&
                      <PdfLink href={FebruPdf} download>PDF</PdfLink>
                    }
                    {moebel[0] === "Matheo" &&
                      <PdfLink href={MatheoPdf} download>PDF</PdfLink>
                    }
                    {moebel[0] === "Milo" &&
                      <PdfLink href={MiloPdf} download>PDF</PdfLink>
                    }
                    {moebel[0] === "Aero" &&
                      <PdfLink href={AeroPdf} download>PDF</PdfLink>
                    }
                    {moebel[0] === "Greta" &&
                      <PdfLink href={GretaPdf} download>PDF</PdfLink>
                    }
                    {moebel[0] === "Theo" &&
                      <PdfLink href={TheoPdf} download>PDF</PdfLink>
                    }
                    {moebel[0] === "Matze" &&
                      <PdfLink href={MatzePdf} download>PDF</PdfLink>
                    }
                    {moebel[0] === "Elisa" &&
                      <PdfLink href={ElisaPdf} download>PDF</PdfLink>
                    } </div> </> :
                      <>
                        {item.postTypeTischeFields.variants.map(m =>
                          <ConfigLi>
                            <Link activeStyle={{ color: "blue", fontSize: "1.2rem" }} to={`/tische/${m.link.slug}`}>
                              <Img fluid={m.img.localFile.childImageSharp.fluid} />
                              <h5 style={{ marginTop: "10px", textAlign: "center" }}>{m.variant}</h5>
                            </Link>
                          </ConfigLi>
                        )}
                      </>
                    }
                  </ConfigUL>
                </InnerModalContainer>
              </Modal>
            </ConfigContainer>



            <ConfigContainer>
              <GeneralDetailsHeadline>Produktdetails</GeneralDetailsHeadline>

              <GeneralDetailsContainer>

                <GeneralDetailsWrapper>
                  <GeneralDetailsLabel>Abmessungen</GeneralDetailsLabel>
                  <GeneralDetailsUl>
                    {item.postTypeTischeFields.dimensions.map(i =>
                      <DetailsLi>
                        {`${i.dimension}  ${i.value}`}
                                </DetailsLi>
                    )}
                  </GeneralDetailsUl>
                </GeneralDetailsWrapper>


                <GeneralDetailsWrapper>
                  <GeneralDetailsLabel>Materialien</GeneralDetailsLabel>
                  <GeneralDetailsUl>
                    {item.postTypeTischeFields.materials &&
                      <>

                        {item.postTypeTischeFields.materials.map(i =>
                          <DetailsLi>
                            {i.materialbeschreibung}
                          </DetailsLi>
                        )}
                      </>
                    }

                  </GeneralDetailsUl>
                </GeneralDetailsWrapper>

                <GeneralDetailsWrapper>
                  <GeneralDetailsLabel>Downloads</GeneralDetailsLabel>
                  <GeneralDetailsUl>
                    <DetailsLi>
                  {moebel[0] === "Rudi" &&
                    <PdfLink href={RudiPdf} download>PDF</PdfLink>
                  }
                  {moebel[0] === "Travel" &&
                    <PdfLink href={TravelPdf} download>PDF</PdfLink>
                  }
                  {moebel[0] === "Febru" &&
                    <PdfLink href={FebruPdf} download>PDF</PdfLink>
                  }
                  {moebel[0] === "Matheo" &&
                    <PdfLink href={MatheoPdf} download>PDF</PdfLink>
                  }
                  {moebel[0] === "Milo" &&
                    <PdfLink href={MiloPdf} download>PDF</PdfLink>
                  }
                  {moebel[0] === "Aero" &&
                    <PdfLink href={AeroPdf} download>PDF</PdfLink>
                  }
                  {moebel[0] === "Greta" &&
                    <PdfLink href={GretaPdf} download>PDF</PdfLink>
                  }
                  {moebel[0] === "Theo" &&
                    <PdfLink href={TheoPdf} download>PDF</PdfLink>
                  }
                  {moebel[0] === "Matze" &&
                    <PdfLink href={MatzePdf} download>PDF</PdfLink>
                  }
                  {moebel[0] === "Elisa" &&
                    <PdfLink href={ElisaPdf} download>PDF</PdfLink>
                  }
                  </DetailsLi>
                  </GeneralDetailsUl>
                </GeneralDetailsWrapper>

              </GeneralDetailsContainer>

            </ConfigContainer>

            <CallToActionContainer>

              {isFav === false &&
                <form onSubmit={handleAdd} >
                  <Like type="submit">
                    <Heart />
                  </Like>
                </form>
              }

              {isFav === true &&
                <form onSubmit={handleRemove} >
                  <Like type="submit">
                    <FilledHeart />
                  </Like>
                </form>
              }

              <Price>Ab {item.postTypeTischeFields.price} €</Price>
            </CallToActionContainer>

          </Right>
        </Wrapper>
        <VisitUsContainer>
          <VisitUsHead>Sie haben das Richtige noch nicht gefunden?</VisitUsHead>
          <VisitUsBody>Dann besuchen Sie uns in unserem Geschäft. Wir helfen Ihnen gern dabei, genau das Richtige für Sie zu finden.</VisitUsBody>
        </VisitUsContainer>

      </Container>


    </Layout>
  )
}

export default Template

export const query = graphql`
query($slug: String!) {
  allWpTisch(filter: {slug: {eq: $slug}}) {
    nodes {
      id
      slug
      seo {
        title
      }
      ausfuehrungen {
        nodes {
          name
        }
      }
      tags {
        nodes {
          name
        }
      }
      postTypeTischeFields {
        claim
        description
        price
        dimensions {
          dimension
          value
        }
        materials {
          materialbeschreibung
        }
        slides {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000, maxHeight: 750, quality: 99, cropFocus: CENTER) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        variants {
          img {
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          link {
            ... on WpTisch {
              id
              slug
            }
          }
          variant
        }
      }
    }
  }
}
`